import axios from "axios";
import { settings } from "@/settings";

const API_SERVER = settings.API_SERVER;

export const api_routes = {
  user: {
    login: API_SERVER + "login",
    signup: API_SERVER + "register",
    me: API_SERVER + "me",
    sendverification: API_SERVER + "send-verification",
    verify: API_SERVER + "verify-user",
    namelookup: API_SERVER + "lookup",
    getnamebynumber: API_SERVER + "get-name",
    forgotpassword: API_SERVER + "auth/forgot",
    passwordreset: API_SERVER + "auth/reset",
    completequickpay: API_SERVER + "complete-quickpay",
    transactioncredits: API_SERVER + "transactioncredits",
    transactiondebits: API_SERVER + "transactiondebits",
    sendveilederemail: API_SERVER + "sendveilederemail"
  },
  bambora: {
    pay: API_SERVER + "bambora",
    quickpay: API_SERVER + "bambora-quick"
  },
  admin: {
    getcustomers: API_SERVER + "admin/getcustomers",
    getcustomerdetails: API_SERVER + "admin/getcustomerdetails",
    makeuserandcustomeraccount: API_SERVER + "admin/makeuserandcustomeraccount",
    getcustomeraccount: API_SERVER + "admin/getcustomeraccount",
    transactioncreditsbycustomerid:
      API_SERVER + "admin/transactioncreditsbycustomerid",
    transactiondebitsbycustomerid: API_SERVER + "admin/transactiondebitsbycustomerid",
    updatecustomerdetails: API_SERVER + "admin/updatecustomerdetails",
    addtransactioncredittocustomeraccount:
      API_SERVER + "admin/addtransactioncredittocustomeraccount",
    disablecustomer: API_SERVER + "admin/disablecustomer",
    credittransaction: API_SERVER + "admin/credit",
    gettransaction: API_SERVER + "admin/gettransaction"
    //deletecustomer: API_SERVER + "deletecustomer",
  }
};

export const apiCall = ({ url, method, ...args }) =>
  new Promise((resolve, reject) => {
    let token = localStorage.getItem("user-token") || "";

    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    try {
      axios({
        method: method || "get",
        url: url,
        ...args
      })
        .then(resp => {
          resolve(resp.data);
        })
        .catch(error => {
          reject(error);
        });
    } catch (err) {
      reject(new Error(err));
    }
  });
