<template>
  <div class="tf-width">
    <div class="uk-align-center">
      <div
        class="uk-width-xlarge uk-card uk-card-small uk-card-body uk-border-rounded tf-admin-box"
      >
        <h4 class="uk-text-capitalize">{{ $t("create_new_account") }}</h4>
        <div uk-grid>
          <div class="uk-width-2-3">
            <vue-tel-input
              v-model="number"
              v-bind="telProps"
              @onInput="onNumberInput"
            />
          </div>
          <div class="uk-width-1-3">
            <button
              class="uk-button uk-button-primary uk-text-capitalize uk-border-pill"
              @click="makeUserAndCustomerAccount()"
            >
              {{ $t("create") }}
            </button>
          </div>
        </div>
      </div>

      <div
        class="uk-search uk-search-default uk-margin-medium-top uk-width-1-2@m tf-admin-box uk-border-rounded"
      >
        <span uk-search-icon></span>
        <input
          class="uk-search-input uk-border-rounded tf-white-background"
          type="search"
          placeholder="Søk på navn eller nummer"
          v-model="query"
          @keyup="getResults(1, query)"
        />
      </div>
      <div>
        <table
          class="uk-padding-small uk-table uk-table-striped uk-table-hover"
        >
          <thead>
            <tr>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("number") }}</th>
              <th>{{ $t("balance") }}</th>
              <th>{{ $t("credit_limit") }}</th>
              <th>{{ $t("credit_status") }}</th>
              <th>{{ $t("enabled") }}</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              tag="tr"
              v-for="(customer, index) in getCustomers.data"
              :key="index"
              :to="{
                name: 'edit-customer',
                params: { customerId: customer.Customer_ID }
              }"
            >
              <template v-if="customer.accounts[0]">
                <td>{{ customer.DateCreated | toDate }}</td>
                <td>{{ customer.CustomerName | unverified }}</td>
                <td>{{ customer.MainNumber }}</td>
                <template v-if="customer.accounts[0].customer_account_prepaid">
                  <td>
                    {{
                      customer.accounts[0].customer_account_prepaid.Balance
                        | round
                        | toCurrency
                    }}
                  </td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
                <template v-if="customer.accounts[0].customer_account_invoice">
                  <td>
                    {{
                      customer.accounts[0].customer_account_invoice.CreditLimit
                        | toCurrency
                    }}
                  </td>
                  <template
                    v-if="customer.accounts[0].customer_account_invoice.Frozen"
                  >
                    <td class="uk-text-danger">{{ $t("frozen") }}</td>
                  </template>
                  <template
                    v-else-if="
                      customer.accounts[0].customer_account_invoice.Frozen == 0
                    "
                  >
                    <td class="uk-text-success">{{ $t("active") }}</td>
                  </template>
                  <template v-else>
                    <td class="uk-text">{{ $t("none") }}</td>
                  </template>
                </template>
                <template v-else>
                  <td>-</td>
                  <td>-</td>
                </template>
                <template v-if="customer.Enabled">
                  <td>
                    <span
                      class="uk-for-icon uk-margin-left tf-green"
                      uk-icon="check"
                    ></span>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <span
                      class="uk-for-icon uk-margin-left tf-red"
                      uk-icon="close"
                    ></span>
                  </td>
                </template>
              </template>
            </router-link>
          </tbody>
        </table>
        <pagination
          class="uk-pagination"
          :data="getCustomers"
          :limit="-1"
          @pagination-change-page="getResults"
        >
          <span class="uk-text-capitalize" slot="prev-nav"
            >&lt; {{ $t("previous") }}</span
          >
          <span class="uk-text-capitalize" slot="next-nav"
            >{{ $t("next") }} &gt;</span
          >
        </pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  ADMIN_GET_CUSTOMERS,
  ADMIN_MAKE_USER_AND_CUSTOMER_ACCOUNT
} from "@/store/actions/admin.js";
import { settings } from "@/settings";
import {
  USER_DISPLAY_RIBBON,
  USER_DISPLAY_RIBBON_ERROR
} from "@/store/actions/user.js";

export default {
  data() {
    return {
      number: "",
      query: "",
      full_number: "",
      telProps: {
        placeholder: "Skriv inn et nummer",
        required: true,
        preferredCountries: ["NO", "SE", "DK"]
      }
    };
  },
  created: function() {},
  name: "admin",
  methods: {
    onNumberInput({ number }) {
      this.full_number = number.e164;
    },
    getResults(page = 1, query = "") {
      this.$store.dispatch(ADMIN_GET_CUSTOMERS, { page: page, query: query });
    },
    makeUserAndCustomerAccount() {
      this.$store
        .dispatch(ADMIN_MAKE_USER_AND_CUSTOMER_ACCOUNT, {
          number: this.full_number
        })
        .then(() => {
          this.$store.dispatch(ADMIN_GET_CUSTOMERS, {
            page: 1,
            query: ""
          });
          this.$store.dispatch(USER_DISPLAY_RIBBON, {
            message: "Ny bruker lagt til"
          });
        });
    }
  },
  computed: {
    ...mapGetters(["getCustomers"])
  },
  beforeMount() {
    this.$store.dispatch(ADMIN_GET_CUSTOMERS, { page: 1, query: this.query });
  }
};
</script>
