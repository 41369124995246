import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import VerificationForm from "@/components/globals/VerificationForm";
import RegistrationForm from "@/components/globals/RegistrationForm";
import i18n from "./i18n";
import VueTelInput from "vue-tel-input";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { settings } from "@/settings";
import vueDebounce from "vue-debounce";
import VueScrollTo from "vue-scrollto";

UIkit.use(Icons);
window.UIkit = UIkit;

// loads the Icon plugin
window._ = require("lodash");

require("./styles/index.scss");

Vue.config.productionTip = false;
window.Event = new Vue();

Vue.use(VueTelInput);
Vue.use(VueScrollTo);

Vue.use(VueReCaptcha, {
  siteKey: settings.GOOGLE_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
});

Vue.use(vueDebounce);

Vue.component("verification-form", VerificationForm);
Vue.component("registration-form", RegistrationForm);
Vue.component("pagination", require("laravel-vue-pagination"));

Vue.filter("unverified", function(name) {
  if (name && name.includes("-unverified")) {
    return "unverified";
  }
  return name;
});

Vue.filter("toDate", function(datetime) {
  return new Date(datetime).toLocaleDateString("nb-NO");
});

Vue.filter("round", function(value = 0, decimals = 0) {
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
});

Vue.filter("toCurrency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter("phone", function(phone) {
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{2})(\d{3})/, "$1 $2 $3");
});

Vue.filter("toHMS", function(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  // add 0 if value < 10
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
