import {
  USER_REQUEST,
  USER_SEND_VERIFICATION_CODE,
  USER_VERIFY,
  USER_NAME_LOOKUP,
  USER_GET_TRANSACTION_CREDITS,
  USER_GET_TRANSACTION_DEBITS,
  USER_SEND_VEILEDER_CONTACT_EMAIL,
  USER_DISPLAY_RIBBON,
  USER_DISPLAY_RIBBON_ERROR,
  USER_RESET_RIBBONS
} from "../actions/user";
import { settings } from "@/settings";
import { apiCall, api_routes } from "@/utils/api";
import { AUTH_LOGOUT } from "../actions/auth";
import axios from "axios";

const state = {
  status: "",
  verified: false,
  profile: {},
  sms: "",
  is_admin: false,
  is_superadmin: false,
  is_customer: false,
  name_lookup: [],
  transactioncredits: {},
  transactiondebits: {},
  send_email: "",
  ribbon_message: {},
  ribbon_error: {}
};

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded(state) {
    return function() {
      return state.profile.name ? true : false;
    };
  },
  isVerified: state => state.verified,
  isAdmin: state => state.is_admin,
  isSuperAdmin: state => state.is_superadmin,
  isCustomer: state => state.is_customer,
  getNameLookup: state => state.name_lookup,
  getTransactionCredits: state => state.transactioncredits,
  getTransactionDebits: state => state.transactiondebits,
  getRibbonMessage: state => state.ribbon_message,
  getRibbonError: state => state.ribbon_error
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.me + "?provider_id=" + settings.PROVIDER_ID
      })
        .then(resp => {
          commit("login_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("login_fail", err);
          // if resp is unauthorized, logout, too
          dispatch(AUTH_LOGOUT);
          reject(err);
        });
    });
  },
  [USER_DISPLAY_RIBBON]: ({ commit }, ribbon) => {
    commit("ribbon_message", ribbon);
  },
  [USER_DISPLAY_RIBBON_ERROR]: ({ commit }, ribbon) => {
    commit("ribbon_error", ribbon);
  },
  [USER_RESET_RIBBONS]: ({ commit }) => {
    commit("ribbon_reset");
  },

  [USER_GET_TRANSACTION_CREDITS]: ({ commit }, page) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url:
          api_routes.user.transactioncredits +
          "?page=" +
          page +
          "&provider_id=" +
          settings.PROVIDER_ID
      })
        .then(resp => {
          commit("transactioncredits_loaded_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("transactions_loaded_error", err);
          reject(err);
        });
    });
  },
  [USER_GET_TRANSACTION_DEBITS]: ({ commit }, page) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url:
          api_routes.user.transactiondebits +
          "?page=" +
          page +
          "&provider_id=" +
          settings.PROVIDER_ID
      })
        .then(resp => {
          commit("transactiondebits_loaded_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("transactions_loaded_error", err);
          reject(err);
        });
    });
  },
  [USER_SEND_VERIFICATION_CODE]: ({ commit }, verification) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.sendverification,
        data: verification,
        method: "post"
      })
        .then(resp => {
          commit("send_verification_code_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("send_verification_code_fail", err);
          reject(err);
        });
    });
  },
  [USER_VERIFY]: ({ commit }, verification) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.verify,
        data: verification,
        method: "post"
      })
        .then(resp => {
          commit("verification_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("verification_fail", err);
          reject(err);
        });
    });
  },
  [USER_NAME_LOOKUP]: ({ commit }, number) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: "get",
        data: number,
        url: api_routes.user.namelookup + "/" + number
      })
        .then(resp => {
          commit("name_lookup_success", resp);
          resolve(resp);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [USER_SEND_VEILEDER_CONTACT_EMAIL]: ({ commit }, request) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.user.sendveilederemail,
        data: request,
        method: "post"
      })
        .then(resp => {
          commit("send_mail_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("send_email_fail", err);
          reject(err);
        });
    });
  }
};

const mutations = {
  ribbon_message(state, ribbon) {
    state.ribbon_message = ribbon;
  },
  ribbon_error(state, ribbon) {
    state.ribbon_error = ribbon;
  },
  ribbon_reset(state) {
    state.ribbon_message = {};
    state.ribbon_error = {};
  },
  login_success(state, resp) {
    state.status = "success";
    state.profile = resp;
    state.verified = resp.verified == 1 ? true : false;
    for (let role of resp.roles) {
      if (role.name === "admin") {
        state.is_admin = true;
      }
      if (role.name === "superadmin") {
        state.is_superadmin = true;
      }
      if (role.name === "customer") {
        state.is_customer = true;
      }
    }
  },
  login_fail(state, err) {
    state.status = err;
  },
  verification_success(state) {
    state.verified = true;
  },
  verification_fail(state) {
    state.verified = false;
  },
  send_email_fail(state, err) {
    state.send_email = err;
  },
  send_verification_code_success(state) {
    state.sms = "Sent successfully";
  },
  send_verification_code_fail(state, err) {
    state.sms = err;
  },
  update_balance(state, resp) {
    state.profile.balance += resp;
  },
  name_lookup_success(state, resp) {
    state.name_lookup = resp;
  },
  transactioncredits_loaded_success(state, resp) {
    state.transactioncredits = resp;
  },
  transactiondebits_loaded_success(state, resp) {
    state.transactiondebits = resp;
  },
  transactions_loaded_error(state) {
    state.transactions = {};
  },
  send_mail_success(state) {
    state.send_email = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
