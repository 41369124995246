<template>
  <div class="tf-width">
    <div class="uk-align-center">
      <h2 class="uk-text-capitalize">
        {{ $t("edit") + " " + $t("customer") }}
      </h2>

      <div
        class="uk-margin-large-bottom uk-width-xlarge uk-card uk-card-small uk-card-body tf-admin-box uk-border-rounded"
      >
        <h4>
          {{
            getCustomerDetails.MainNumber
              ? getCustomerDetails.MainNumber.slice(3)
              : "" | phone
          }}
        </h4>
        <hr />
        <h4 class="uk-text-capitalize">
          {{ Number(this.balance) | round | toCurrency }}
        </h4>
      </div>
      <ul class="uk-tab" data-uk-tab="{connect:'#transaction-id'}">
        <li>
          <a>{{ $t("customer") }}</a>
        </li>
        <li>
          <a>{{ $t("transaction_credits") }}</a>
        </li>
        <li>
          <a>{{ $t("transaction_debits") }}</a>
        </li>
      </ul>
      <ul id="transaction-id" class="uk-switcher uk-margin">
        <li>
          <div
            class="uk-width-xlarge uk-card uk-card-small uk-card-body tf-admin-box uk-border-rounded"
          >
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                {{ $t("name") }}
              </label>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="customer_name"
                id="customer_name"
              />
            </div>
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                {{ $t("email") }}
              </label>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="email"
                id="email"
              />
            </div>
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                {{ $t("street_address") }}
              </label>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="street_address"
                id="street_address"
              />
            </div>
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                {{ $t("post_code") }}
              </label>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="postcode"
                id="postcode"
              />
            </div>
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                {{ $t("city") }}
              </label>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="city"
                id="city"
              />
            </div>
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                {{ $t("country") }}
              </label>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="country"
                id="country"
              />
            </div>
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                <input class="uk-checkbox" type="checkbox" v-model="enabled" />
                {{ $t("enabled") }}
              </label>
            </div>
            <hr />
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                {{ $t("credit_limit") }}
              </label>
              <input
                class="uk-input uk-border-rounded"
                type="text"
                v-model="credit_limit"
                id="credit_limit"
              />
            </div>
            <div class="uk-margin">
              <label class="uk-text-capitalize uk-form-label">
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="frozen"
                  id="frozen"
                />
                {{ $t("frozen") }}
              </label>
            </div>
            <div>
              <button
                class="uk-button uk-button-primary uk-border-pill uk-text-capitalize"
                @click.stop="updateCustomer()"
              >
                {{ $t("update") + " " + $t("customer") }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <a href="#" id="autoplayer" data-uk-switcher-item="next"></a>
          <div
            class="uk-width-xlarge uk-margin tf-admin-box uk-card uk-card-small uk-card-body uk-border-rounded"
          >
            <h4>{{ $t("gift_free_minutes") }}</h4>

            <div uk-grid>
              <div>
                <input
                  class="uk-width-medium uk-input uk-border-rounded"
                  type="text"
                  v-model="gift_amount"
                  placeholder="Beløp"
                  id="gift_amount"
                />
              </div>

              <div class="uk-float-left">
                <button
                  class="uk-button uk-button-primary uk-border-pill"
                  @click.stop="addGift()"
                >
                  Legg til
                </button>
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <table
              class="uk-padding-small uk-table uk-table-striped uk-table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("date") }}</th>
                  <th>Bambora ID</th>
                  <th>{{ $t("status") }}</th>
                  <th>{{ $t("source") }}</th>
                  <th>{{ $t("amount") }}</th>
                  <th>Refundert</th>
                </tr>
              </thead>
              <tbody>
                <router-link
                  tag="tr"
                  v-for="(credit,
                  index) in getTransactionCreditsByCustomerId.data"
                  :key="index"
                  :to="{
                    name: 'refund-customer',
                    params: {
                      tId: credit.TransactionCredit_ID,
                      cId: getCustomerDetails.Customer_ID
                    }
                  }"
                >
                  <td>{{ credit.Date }}</td>

                  <td>{{ credit.ExternalTransaction_ID }}</td>

                  <template v-if="credit.TransactionStatus == 4">
                    <td>
                      <span
                        class="uk-for-icon uk-margin-left tf-green"
                        uk-icon="check"
                      ></span>
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <span
                        class="uk-for-icon uk-margin-left tf-red"
                        uk-icon="close"
                      ></span>
                    </td>
                  </template>
                  <td>{{ credit.ChargeInitiator == 2 ? "web" : "tsip" }}</td>
                  <td>{{ Number(credit.CreditAmount) | toCurrency }}</td>
                  <td>{{ Number(credit.RefundAmount) | toCurrency }}</td>
                </router-link>
              </tbody>
            </table>
            <pagination
              class="uk-pagination"
              :data="getTransactionCreditsByCustomerId"
              :limit="-1"
              @pagination-change-page="getCreditResults"
            >
              <span class="uk-text-capitalize" slot="prev-nav"
                >&lt; {{ $t("previous") }}</span
              >
              <span class="uk-text-capitalize" slot="next-nav"
                >{{ $t("next") }} &gt;</span
              >
            </pagination>
          </div>
        </li>

        <li>
          <div class="uk-margin">
            <table class="uk-padding-small uk-table uk-table-striped">
              <thead>
                <tr>
                  <th>{{ $t("date") }}</th>
                  <th>{{ $t("duration") }}</th>
                  <th>{{ $t("amount") }}</th>
                  <th>{{ $t("balance") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(debit, index) in this.getTransactionDebitsByCustomerId
                    .data"
                  :key="index"
                >
                  <td>{{ debit.Date }}</td>
                  <td>{{ debit.Duration | toHMS }}</td>
                  <td>{{ debit.Amount | toCurrency }}</td>
                  <td>{{ debit.NewBalance | toCurrency }}</td>
                </tr>
              </tbody>
            </table>
            <pagination
              class="uk-pagination"
              :data="getTransactionDebitsByCustomerId"
              :limit="-1"
              @pagination-change-page="getDebitResults"
            >
              <span class="uk-text-capitalize" slot="prev-nav"
                >&lt; {{ $t("previous") }}</span
              >
              <span class="uk-text-capitalize" slot="next-nav"
                >{{ $t("next") }} &gt;</span
              >
            </pagination>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { settings } from "@/settings";
import {
  USER_DISPLAY_RIBBON,
  USER_DISPLAY_RIBBON_ERROR
} from "@/store/actions/user.js";
import router from "./../../../router";
import {
  ADMIN_GET_CUSTOMER_DETAILS,
  ADMIN_GET_TRANSACTION_CREDITS_BY_CUSTOMER_ID,
  ADMIN_GET_TRANSACTION_DEBITS_BY_CUSTOMER_ID,
  ADMIN_UPDATE_CUSTOMER_DETAILS,
  ADMIN_ADD_TRANSACTION_TO_CUSTOMER_ACCOUNT
} from "@/store/actions/admin.js";

export default {
  data() {
    return {
      number: "",
      credit_limit: "",
      frozen: "",
      customer_name: "",
      email: "",
      street_address: "",
      postcode: "",
      city: "",
      country: "",
      gift_amount: "",
      balance: "",
      enabled: "",
      credit_reason: "",
      credit_amount: "",
      bambora_url:
        settings.BAMBORA_ADMIN_PANEL_URL +
        settings.BAMBORA_MERCHANT_ID +
        "/payments/"
    };
  },
  created: function() {},
  name: "edit-customer",
  methods: {
    updateCustomer() {
      this.$store
        .dispatch(ADMIN_UPDATE_CUSTOMER_DETAILS, {
          Customer_ID: this.$route.params.customerId,
          CustomerName: this.customer_name,
          CreditLimit: this.credit_limit,
          Frozen: this.frozen,
          EmailAddress: this.email,
          StreetAddress: this.street_address,
          PostCode: this.postcode,
          City: this.city,
          Country: this.country,
          Enabled: this.enabled
        })
        .then(() => {
          router.push("/admin");
        });
    },
    addGift() {
      this.$store
        .dispatch(ADMIN_ADD_TRANSACTION_TO_CUSTOMER_ACCOUNT, {
          CustomerAccount_ID: this.getCustomerDetails.accounts[0]
            .CustomerAccount_ID,
          amount: this.gift_amount
        })
        .then(() => {
          this.$store.dispatch(ADMIN_GET_TRANSACTION_CREDITS_BY_CUSTOMER_ID, {
            id: this.$route.params.customerId,
            page: 1
          });
          this.balance = Number(this.balance) + Number(this.gift_amount);

          this.$store.dispatch(USER_DISPLAY_RIBBON, {
            message: "Gave lagt til"
          });
        });
    },
    getCreditResults(page = 1) {
      this.$store.dispatch(ADMIN_GET_TRANSACTION_CREDITS_BY_CUSTOMER_ID, {
        id: this.$route.params.customerId,
        page: page
      });
    },
    getDebitResults(page = 1) {
      this.$store.dispatch(ADMIN_GET_TRANSACTION_DEBITS_BY_CUSTOMER_ID, {
        id: this.$route.params.customerId,
        page: page
      });
    }
  },
  computed: {
    ...mapGetters([
      "getCustomerDetails",
      "getTransactionCreditsByCustomerId",
      "getTransactionDebitsByCustomerId"
    ])
  },
  beforeMount() {
    this.$store
      .dispatch(ADMIN_GET_CUSTOMER_DETAILS, this.$route.params.customerId)
      .then(() => {
        this.customer_name = this.getCustomerDetails.CustomerName;
        this.credit_limit = this.getCustomerDetails.accounts[0]
          .customer_account_invoice
          ? this.getCustomerDetails.accounts[0].customer_account_invoice
              .CreditLimit
          : "";
        this.frozen = this.getCustomerDetails.accounts[0]
          .customer_account_invoice
          ? this.getCustomerDetails.accounts[0].customer_account_invoice.Frozen
          : 0;
        this.email = this.getCustomerDetails.EmailAddress;
        this.street_address = this.getCustomerDetails.StreetAddress;
        this.postcode = this.getCustomerDetails.PostCode;
        this.city = this.getCustomerDetails.City;
        this.country = this.getCustomerDetails.Country;
        this.balance = this.getCustomerDetails.accounts[0].customer_account_prepaid.Balance;
        this.enabled = this.getCustomerDetails.Enabled;
      });

    this.$store.dispatch(ADMIN_GET_TRANSACTION_CREDITS_BY_CUSTOMER_ID, {
      id: this.$route.params.customerId,
      page: 1
    });

    this.$store.dispatch(ADMIN_GET_TRANSACTION_DEBITS_BY_CUSTOMER_ID, {
      id: this.$route.params.customerId,
      page: 1
    });
  }
};
</script>
