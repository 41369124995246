<template>
  <div class="uk-align-center uk-margin-bottom">
    <div class="tf-green-background uk-light tf-profile-padding">
      <div class="tf-width">
        <div class="uk-grid-expand-children" uk-grid>
          <div>
            <h1 class="uk-text-lighter">Min side</h1>
          </div>
          <div>
            <p class="tf-large-paragraph">{{ getProfile.name }}</p>
            <p class="tf-heading-medium">{{ this.formatted_number }}</p>
            <p class="tf-heading-medium">
              Saldo: {{ getProfile.balance | toCurrency }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-width-1-2@xl uk-width-1-2@l uk-width-2-3@s uk-align-center">
      <ul class="uk-tab" data-uk-tab="{connect:'#transaction-id'}">
        <li>
          <a class="tf-info-text uk-text-capitalize" href>
            {{ $t("transaction_credits") }}
          </a>
        </li>
        <li>
          <a class="tf-info-text uk-text-capitalize" href>
            {{ $t("transaction_debits") }}
          </a>
        </li>
      </ul>
      <ul id="transaction-id" class="uk-switcher">
        <li>
          <div>
            <table class="uk-table uk-table-medium uk-table-striped">
              <thead>
                <tr>
                  <th>{{ $t("date") }}</th>
                  <th>{{ $t("status") }}</th>
                  <th>{{ $t("source") }}</th>
                  <th>{{ $t("amount") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, index) in getTransactionCredits.data"
                  :key="index"
                >
                  <td>{{ transaction.Date | toDate }}</td>
                  <template v-if="transaction.TransactionStatus == 4">
                    <td>
                      <span
                        class="uk-for-icon uk-margin-left tf-green"
                        uk-icon="check"
                      ></span>
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <span
                        class="uk-for-icon uk-margin-left tf-red"
                        uk-icon="close"
                      ></span>
                    </td>
                  </template>

                  <td>{{ getSource(Number(transaction.ChargeInitiator)) }}</td>
                  <td>{{ Number(transaction.CreditAmount) | toCurrency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            class="uk-padding-small uk-pagination"
            :data="getTransactionCredits"
            :limit="-1"
            @pagination-change-page="getCreditResults"
          >
            <span class="uk-text-capitalize" slot="prev-nav"
              >&lt; {{ $t("previous") }}</span
            >
            <span class="uk-text-capitalize" slot="next-nav"
              >{{ $t("next") }} &gt;</span
            >
          </pagination>
        </li>

        <li>
          <table class="uk-table uk-table-medium uk-table-striped">
            <thead>
              <tr>
                <th>{{ $t("date") }}</th>
                <th>{{ $t("duration") }}</th>
                <th>{{ $t("amount") }}</th>
                <!--th>{{ $t("balance") }}</th-->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(debit, index) in this.getTransactionDebits.data"
                :key="index"
              >
                <td>{{ debit.Date | toDate }}</td>
                <td>{{ debit.Duration | toHMS }}</td>
                <td>{{ debit.Amount | toCurrency }}</td>
                <!--td>{{ debit.NewBalance | round | toCurrency }}</td-->
              </tr>
            </tbody>
          </table>
          <pagination
            class="uk-padding-small uk-pagination"
            :data="getTransactionDebits"
            :limit="-1"
            @pagination-change-page="getDebitResults"
          >
            <span class="uk-text-capitalize" slot="prev-nav"
              >&lt; {{ $t("previous") }}</span
            >
            <span class="uk-text-capitalize" slot="next-nav"
              >{{ $t("next") }} &gt;</span
            >
          </pagination>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PhoneNumber from "awesome-phonenumber";
import {
  USER_REQUEST,
  USER_GET_TRANSACTION_CREDITS,
  USER_GET_TRANSACTION_DEBITS
} from "@/store/actions/user";

export default {
  data() {
    return {
      contact_number: "",
      code: "",
      balance: "",
      amount: "",
      should_store_token: true
    };
  },
  created: function() {},
  name: "profile",
  methods: {
    getSource(source) {
      switch (source) {
        case 1:
          return "telefon";
        case 2:
          return "web";
      }
    },
    getCreditResults(page = 1) {
      this.$store.dispatch(USER_GET_TRANSACTION_CREDITS, page);
    },
    getDebitResults(page = 1) {
      this.$store.dispatch(USER_GET_TRANSACTION_DEBITS, page);
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "getToken",
      "isVerified",
      "isAuthenticated",
      "isAdmin",
      "isSuperAdmin",
      "getTransactionCredits",
      "getTransactionDebits"
    ]),
    formatted_number() {
      if (this.getProfile.main_number) {
        return new PhoneNumber(this.getProfile.main_number).getNumber(
          "significant"
        );
      }
      return null;
    }
  },
  beforeMount() {
    if (this.isAuthenticated) {
      if (this.isAuthenticated) {
        this.$store.dispatch(USER_REQUEST);
      }
      this.$store.dispatch(USER_GET_TRANSACTION_CREDITS, 1);
      this.$store.dispatch(USER_GET_TRANSACTION_DEBITS, 1);
    }
  }
};
</script>
