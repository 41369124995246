<template>
  <div ref="localTop"
    class="uk-padding-large uk-align-center uk-width-2-5@xl uk-width-1-2@m uk-width-2-3@s uk-card uk-card-body uk-card-default uk-border-rounded uk-box-shadow-medium"
  >
    <div class="uk-margin">
      <h1 class="uk-text-center uk-text-lighter">Registrer deg</h1>
      <div
        class="uk-width-4-5@xl uk-text-center tf-info-background tf-info-text uk-align-center uk-border-rounded uk-padding-small"
      >
        <div>
          <span uk-icon="icon: question; ratio:1.3" />
        </div>

        <span>
          Husk at du alltid er anonym når du ringer en veileder, selv om du har
          registrert bruker.
        </span>
      </div>
      <div class="tf-label-text" for="text">Nummer (kan ikke endres)</div>
      <input
        class="uk-input uk-border-rounded"
        type="text"
        v-model="number"
        id="number"
        readonly
        disabled
      />
      <br />
      <br />
      <div class="uk-text-center uk-margin">
        <p class="uk-margin-medium tf-large-paragraph">Verifiser nummeret ditt</p>
      </div>

      <button
        v-if="getType() === 'mobile'"
        class="tf-dark-gray-background uk-align-center uk-margin-remove-top uk-button uk-border-pill"
        @click="sendVerificationCode()"
      >Send på nytt</button>

      <button
        v-if="getType() === 'fixed-line'"
        class="tf-dark-gray-background uk-align-center uk-margin-remove-top uk-button uk-border-pill"
        @click="sendVerificationCode()"
      >Ring meg på nytt</button>
    </div>
    <div class="uk-align-center uk-margin uk-width-1-2@xl uk-width-2-3@l">
      <div class="tf-label-text" for="text">Tast inn kode</div>
      <input
        v-debounce:300ms="verify()"
        class="uk-input uk-border-rounded"
        type="text"
        v-model="code"
        id="code"
      />
    </div>
    <br />
    <button
      class="uk-align-center uk-margin uk-button uk-button-primary uk-border-pill"
      @click="toRegistration()"
      :disabled="!this.verified"
    >
      Fullfør og registrer bruker
      <span
        class="tf-button-chevron uk-float-right"
        uk-icon="icon: chevron-right; ratio:1.3"
      />
    </button>
  </div>
</template>

<script>
import { settings } from "@/settings";
import {
  USER_SEND_VERIFICATION_CODE,
  USER_VERIFY,
  USER_DISPLAY_RIBBON,
  USER_DISPLAY_RIBBON_ERROR
} from "@/store/actions/user";
import PhoneNumber from "awesome-phonenumber";
export default {
  name: "verification-form",
  props: ["number"],
  data() {
    return {
      code: "",
      verified: false
    };
  },
  methods: {
    sendVerificationCode() {
      this.$recaptcha("login")
        .then(token => {
          this.$store
            .dispatch(USER_SEND_VERIFICATION_CODE, {
              contact_number: this.number,
              recaptcha_token: token
            })
            .then(() => {
              if (this.getType() === "mobile") {
                this.$store.dispatch(USER_DISPLAY_RIBBON, {
                  message: "Vi har sendt deg en kode per SMS"
                });
              } else if (this.getType() === "fixed-line") {
                this.$store.dispatch(USER_DISPLAY_RIBBON, {
                  message:
                    "Vi forsøker nå å ringe deg for å lese opp verifiseringskoden"
                });
              }
            });
        })
        .catch(error => {
          if (this.getType() === "mobile") {
            this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
              message:
                "Noe gikk galt under utsending av SMS, venligst ta kontakt med oss på " +
                settings.SUPPORT_EMAIL
            });
          } else if (this.getType() === "fixed-line") {
            this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
              message:
                "Noe gikk galt under oppringing for opplesing av kode, venligst ta kontakt med oss på " +
                settings.SUPPORT_EMAIL
            });
          }
        });
    },
    toRegistration() {
      this.$emit("verify:user", true);
    },
    verify() {
      this.$recaptcha("login")
        .then(token => {
          if (this.code.length >= 4) {
            this.$store
              .dispatch(USER_VERIFY, {
                main_number: this.number,
                code: this.code,
                recaptcha_token: token
              })
              .then(() => {
                this.verified = true;
              })
              .catch(error => {
                this.verified = false;
              });
          }
        })
        .catch(error => {
          this.verified = false;
          this.$emit("verify:user", false);
          this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
            message:
              "Noe gikk galt under verifisering av kode, venligst ta kontakt med oss på " +
              settings.SUPPORT_EMAIL
          });
        });
    },
    getType() {
      return new PhoneNumber(this.number).getType();
    }
  },
  mounted() {
    this.$scrollTo(this.$refs["localTop"], 500, {
      offset: -200,
      x: false,
      y: true
    });
    this.sendVerificationCode();
  }
};
</script>

<style scoped></style>
