<template>
  <div class="tf-page-container uk-grid-collapse" uk-grid>
    <div class="tf-light-background uk-width-1-2@m">
      <div class="tf-box-left uk-align-right">
        <h1 class="uk-text-lighter tf-about-margin">Om Tjenesten</h1>
        <p class="tf-large-paragraph">Gode samtaler i trygge rammer!</p>
        <p class="uk-text-lighter">
          Veiledertjenesten.no er levert av SanaVia AS, og er en nettside for
          kjøp av "digitalt" ringekort mot våre veiledere. Veilederne står selv
          ansvarlige for innholdet i tjenesten. Det viderebringes ingen
          opplysninger til veileder om deg som kunde, og du kan velge å være
          helt anonym.
        </p>

        <p class="uk-text-lighter">
          Hver enkelt veileder har et eget telefonnummer for tjenesten
          (fasttelefon nummer), og kan nås på dette så lenge du har et ringekort
          med tilgjengelig saldo.
        </p>
        <p class="uk-text-lighter">
          Ved å forhåndsbetale har du full kontroll på forbruk og det tilkommer
          ingen ekstra kostnader på bruk av tjenesten. Kjøpsprosessen er enkel,
          og når betaling er gjennomført med kortbetaling (Visa/Mastercard) kan
          du ringe din veileder.
        </p>
        <p class="uk-text-lighter">
          Har du valgt å lagre kortopplysningene kan du via telefonen fylle på
          kontoen din før og under samtalen med din veileder.
        </p>
        <p
          class="uk-text-lighter"
        >Du kan ved oppringing taste 1 for å viderekobles direkte til veileder uten å gå via vår velkomstmeny.</p>
      </div>
    </div>
    <div class="uk-light uk-width-1-2@m tf-green-background">
      <div class="tf-box-right uk-margin-large-top">
        <h1 class="uk-text-lighter">Ofte stilte spørsmål</h1>
        <br />
        <ul class="tf-light-text" uk-accordion>
          <li class="uk-open">
            <a class="uk-accordion-title" href="#">Hva koster tjenesten?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Tjenesten koster kr. 26,90 per minutt. Du ringer
                veileder på et fastnummer som sikrer deg mot ekstra
                samtaleutgifter.
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Hvor kan jeg benytte ringekortet?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Ringekortet kan benyttes til å ringe veiledere på
                <a
                  class="tf-underline"
                  href="https://minkrystallkule.no"
                  target="_blank"
                >minkrystallkule.no</a>,
                <a class="tf-underline" href="https://tarot.no" target="_blank">tarot.no</a>
                og
                <a
                  class="tf-underline"
                  href="https://klarsynte.no"
                  target="_blank"
                >klarsynte.no</a>
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Kan ringekortet benyttes til flere veiledere?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Ja, ringekortet kan benyttes fritt til flere veiledere så lenge
                du har tilgjengelig saldo.
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">
              Kan ringekort kjøpes på vegne av andre, for eksempel som en
              gave?
            </a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Ja, ved å legge inn telefonnummeret kjøpet gjelder for ved
                betaling.
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Behøver jeg å registrere meg?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Nei, registrering er valgfritt. Velger du å registrere deg, vil
                du kunne se forbruk og samtalehistorikk på Min Side.
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Kan jeg benytte flere betalingskort?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Ja. Du kan fylle på kontoen din med flere kort. Ved påfylling
                per telefon er det siste kortet du betalte med gjeldende.
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Er man anonym overfor veileder?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">Ja, veileder kan ikke se hvilket nummer det ringes fra.</p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Lagres mine data ved kortbetaling?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Dette er valgfritt. Du kan velge å lagre dine data for å
                forenkle fremtidige kjøp av ringekort. Dersom du ønsker å slette
                data lagret om deg kan du kontakte oss på {{ supportEmail }}
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Hva er fordelen med å lagre kortet ved betaling?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Hvis du velger å lagre kortopplysningene ved betaling på nett,
                vil du kunne fylle på kontoen din før eller underveis i samtalen
                med veileder. Husk at om du får et nytt kort fra banken din må
                du gjøre et nytt kjøp på nett for å lagre de nye detaljene.
              </p>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">Hvordan fyller jeg på kontoen via telefon?</a>
            <div class="uk-accordion-content">
              <p class="uk-margin-bottom">
                Når du ringer din veileder vil du få opplest en kort meny. Tastevalg 2 gir deg mulighet til å fylle på kontoen før samtale. Når samtaletiden er oppbrukt, så får du mulighet til å fylle på mere ved å trykke 2. Tiden du bruker på å fylle på belastes ikke og veilederen vil vente på deg. Ved vellykket påfyll vil samtalen fortsette. Du kan også når som helst fylle på kontoen din via nettsiden mens du snakker.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { settings } from "@/settings";
export default {
  data() {
    return {};
  },
  created: function() {},
  name: "about",
  computed: {
    supportEmail() {
      return settings.SUPPORT_EMAIL;
    }
  }
};
</script>
