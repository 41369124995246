export const settings = {
  APP_NAME: "Veiledertjenesten",
  API_SERVER: "https://prepaid.telefactory.no/api/",
  //API_SERVER: "https://api.veiledertjenesten.no/api/",
  //API_SERVER: "http://localhost:8000/api/",
  BAMBORA_ADMIN_PANEL_URL: "https://merchant.bambora.com/",
  BAMBORA_MERCHANT_ID: "T157004901",
  //BAMBORA_MERCHANT_ID: "P768870502",
  GOOGLE_RECAPTCHA_SITE_KEY: "6Lfs4bwUAAAAAGil8ZsWlO6hYeuEScnwgCoHo71Y",
  //GOOGLE_RECAPTCHA_SITE_KEY: "6LeS4bwUAAAAAGglTIAsu_z_9tbzs038ezko5H0R",
  REFERRER_SITES: ["minkrystallkule.no", "tarot.no", "klarsynte.no"],
  SUPPORT_EMAIL: "kundesupport@veiledertjenesten.no",
  PROVIDER_ID: 17
};
