<template>
  <div class="tf-width">
    <div class="uk-align-center uk-width-large uk-card uk-card-body tf-admin-box uk-border-rounded">
      <h2>Refundere kjøp</h2>
      <p>
        Er du sikker på at du vil refundere kjøpet? Denne operasjonen kan ikke
        tilbakestilles
      </p>
      <ul class="uk-list">
        <li>Totalbeløp: {{ this.credit.CreditAmount | toCurrency }}</li>
        <li>Refundert: {{ this.credit.RefundAmount | toCurrency }}</li>
      </ul>
      <div class>
        <div>
          <label class="uk-text-capitalize uk-form-label">Beløp</label>
        </div>

        <input
          class="uk-input uk-width-2-3@m uk-border-rounded"
          type="number"
          v-model="credit_amount"
          placeholder="Beløp"
        />
        <span class="uk-margin-small-left">av {{ getMaxRefundable() | toCurrency }}</span>
      </div>
      <div class="uk-margin">
        <label class="uk-text-capitalize uk-form-label">Årsak</label>
        <textarea
          class="uk-textarea uk-border-rounded"
          rows="5"
          v-model="credit_reason"
          placeholder="Årsak"
        />
      </div>
      <button
        class="uk-align-left uk-button uk-button-danger uk-border-pill uk-text-capitalize"
        @click.stop="creditTransaction()"
      >refunder</button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import router from "./../../../router";
import { ADMIN_CREDIT_TRANSACTION } from "@/store/actions/admin.js";
import { ADMIN_GET_TRANSACTION } from "@/store/actions/admin.js";
import { settings } from "@/settings";
import {
  USER_DISPLAY_RIBBON,
  USER_DISPLAY_RIBBON_ERROR
} from "@/store/actions/user.js";

export default {
  data() {
    return {
      credit_amount: "",
      txn_id: "",
      credit_reason: "",
      account_id: "",
      transaction_credit_id: "",
      credit: {}
    };
  },
  beforeCreate() {
    this.$store
      .dispatch(ADMIN_GET_TRANSACTION, {
        transaction_credit_id: this.$route.params.tId
      })
      .then(() => {
        this.credit = this.getTransaction;
      })
      .catch(error => {
        this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
          message: "Noe gikk galt under lasting av transaksjoner"
        });
      });
  },
  name: "refund-customer",
  methods: {
    creditTransaction() {
      this.$store
        .dispatch(ADMIN_CREDIT_TRANSACTION, {
          amount: this.credit_amount,
          txn_id: this.credit.ExternalTransaction_ID,
          reason: this.credit_reason,
          account_id: this.credit.CustomerAccount_ID,
          transaction_credit_id: this.credit.TransactionCredit_ID
        })
        .then(() => {
          this.$store.dispatch(USER_DISPLAY_RIBBON, {
            message: "Refundering av kr" + this.credit_amount + " fullført"
          });
          router.push({
            name: "edit-customer",
            params: { customerId: this.$route.params.cId }
          });
        })
        .catch(error => {
          this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
            message: "Noe gikk galt under kreditering av transaksjonen"
          });
        });
    },
    getMaxRefundable() {
      return this.credit.CreditAmount - this.credit.RefundAmount;
    }
  },
  computed: {
    ...mapGetters(["getTransaction"])
  }
};
</script>
