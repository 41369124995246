<template>
  <div class="tf-page-container uk-grid-collapse" uk-grid>
    <div class="uk-width-1-2@m">
      <div class="tf-fix-box-width tf-box-left uk-align-right">
        <h1 class="uk-text-lighter">Logg inn på Min side</h1>
        <br />
        <div
          class="uk-width-2-3@s uk-width-1-1@m uk-padding tf-info-background uk-border-rounded"
        >
          <p class="tf-large-paragraph">Her kan du:</p>
          <ul class="uk-list">
            <li>
              <h4>
                <span class="tf-green" uk-icon="icon: check; ratio: 1.5"></span>
                Sjekke saldo
              </h4>
            </li>
            <li class>
              <h4>
                <span class="tf-green" uk-icon="icon: check; ratio: 1.5"></span>
                Se forbruk
              </h4>
            </li>
            <li>
              <h4>
                <span class="tf-green" uk-icon="icon: check; ratio: 1.5"></span>
                Se Samtalehistorikk
              </h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="uk-width-1-2@m tf-light-background">
      <div class="tf-box-right uk-margin-large-top">
        <div class="uk-margin">
          <div class="tf-label-text">
            <label for="email">E-postadresse</label>
          </div>
          <input
            class="uk-input tf-input-no-border uk-border-rounded"
            type="email"
            v-model="email"
            id="email"
          />
        </div>

        <div class="uk-margin">
          <div class="tf-label-text">
            <label for="password">Passord</label>
          </div>
          <input
            class="uk-input tf-input-no-border uk-border-rounded"
            type="password"
            v-model="password"
            id="password"
          />
        </div>
        <br />
        <div class="uk-text-center">
          <button
            class="uk-button uk-button-primary uk-border-pill"
            @click="login()"
          >
            Logg inn
          </button>
        </div>
        <div class="uk-margin-medium uk-text-center">
          <div class="tf-info-text">
            <span>Glemt passord?&nbsp;</span>
            <router-link class="tf-underline" to="/reset-password"
              >Klikk her</router-link
            >
          </div>
        </div>
        <div
          class="uk-align-center uk-text-center uk-width-2-3@s uk-border-rounded tf-white-background uk-padding-small"
        >
          <span class uk-icon="icon: question; ratio: 1.3"></span>
          <p>
            Har du ikke registrert bruker før, kan du gjøre det neste gang du
            betaler på nett.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";
import { settings } from "@/settings";
import {
  USER_DISPLAY_RIBBON,
  USER_DISPLAY_RIBBON_ERROR
} from "@/store/actions/user";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    login() {
      const { email, password } = this;
      this.$store
        .dispatch(AUTH_REQUEST, { email, password })
        .then(() => {
          this.$router.push(this.$route.params.redirect || "/profile");
        })
        .catch(error => {
          this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
            message:
              "Noe gikk galt. Dobbeltsjekk epostaddresse og passord og forsøk igjen. Venligst ta kontakt med oss på " +
              settings.SUPPORT_EMAIL +
              " dersom problemet vedvarer."
          });
        });
    }
  }
};
</script>
