<template>
  <div>
    <div class="tf-page-container uk-grid-collapse" uk-grid>
      <div class="uk-width-1-2@m">
        <div class="tf-box-left uk-align-right">
          <h1 class="uk-text-lighter">Bli veileder</h1>
          <p class="uk-margin-medium tf-large-paragraph">
            Det er fortsatt et stort behov for å kunne snakke med mennesker.
          </p>
          <p class="uk-text-lighter">
            Veiledertjenesten.no er en tjeneste der publikum på en enkel og
            trygg måte kan betale for veiledningssamtaler. Ved kjøp av ringekort
            får de tilgang til alle veiledere tilknyttet våre tjenester. Din
            bedrift får en profesjonell partner på betaling og telefoni, og et
            eget 21xxxxxx fastnummer tilhørende tjenesten. Det medfølger en egen
            administrasjonsside for betjening av telefoniløsningen. Det sendes
            ut månedlige avregninger og utbetalinger basert på trafikk til ditt
            veiledernummer. Det kreves ingen investeringer i utstyr. Alle
            samtaler viderekobles fra veiledernummeret til det nummeret du
            ønsker å besvare samtaler på. Det være seg mobil, fasttelefon eller
            ip telefon.
          </p>
          <p class="uk-text-lighter">
            Dersom du har kompetanse og ønsker å tilby veiledning via telefon,
            så ta gjerne kontakt for en nærmere vurdering av et mulig samarbeid.
            Dersom din bedrift allerede er en etablert aktør i markedet vil
            veildertjenesten.no være et fint tilskudd til dine tjenester!
          </p>
          <p class="uk-margin-medium tf-large-paragraph">
            Gode samtaler i trygge rammer!
          </p>
        </div>
      </div>
      <form @submit="validateForm" class="uk-width-1-2@m tf-beige-background">
        <div class="tf-box-right">
          <br />
          <p class="tf-large-paragraph">Søknadskjema</p>

          <div ref="validateErrors">
            <p v-if="errors.length">
              <b>Følgende feil må utbedres:</b>
            </p>
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>

          <div class="uk-margin">
            <div class="tf-label-text">
              <label for="email">Fullt navn</label>
            </div>
            <input
              class="uk-input tf-input-no-border uk-border-rounded"
              type="text"
              v-model="name"
              id="name"
            />
          </div>

          <div class="uk-margin">
            <div class="tf-label-text">
              <label for="email">E-postadresse</label>
            </div>
            <input
              class="uk-input tf-input-no-border uk-border-rounded"
              type="email"
              v-model="email"
              id="email"
            />
          </div>

          <div class="uk-margin">
            <div class="tf-label-text">
              <label for="email">Kontaktnummer</label>
            </div>
            <input
              class="uk-input tf-input-no-border uk-border-rounded"
              type="text"
              v-model="number"
              id="number"
            />
          </div>

          <div class="uk-margin">
            <div class="tf-label-text">
              <label for="email">Melding</label>
            </div>
            <textarea
              class="uk-textarea tf-input-no-border uk-border-rounded"
              rows="7"
              v-model="message"
              id="message"
            />
          </div>

          <div class="uk-margin-top">
            <input
              class="uk-checkbox tf-input-no-border uk-border-rounded"
              type="checkbox"
              v-model="has_skills"
              id="has_skills"
            />
            <span class="tf-label-text">
              <label class="tf-margin-left-5" for="has_skills">
                Jeg har kompetanse innen coaching/veiledning
                <a></a>
              </label>
            </span>
          </div>

          <div class="uk-margin-bottom tf-margin-top-5">
            <input
              class="uk-checkbox tf-input-no-border uk-border-rounded"
              type="checkbox"
              v-model="accepted_gdpr"
              id="accepted_gdpr"
            />
            <span class="tf-label-text">
              <label class="tf-margin-left-5" for="accepted_gdpr">
                Jeg har lest
                <router-link class="tf-underline" to="/privacy"
                  >personvernerklæringen</router-link
                >
              </label>
            </span>
          </div>

          <div class="uk-text-center">
            <button
              :disabled="!accepted_gdpr"
              class="uk-button uk-button-primary uk-border-pill uk-width-small"
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { USER_SEND_VEILEDER_CONTACT_EMAIL } from "@/store/actions/user";
import PhoneNumber from "awesome-phonenumber";
import {
  USER_DISPLAY_RIBBON,
  USER_DISPLAY_RIBBON_ERROR
} from "../../store/actions/user";
export default {
  data() {
    return {
      name: "",
      email: "",
      number: "",
      message: "",
      has_skills: 0,
      errors: [],
      accepted_gdpr: ""
    };
  },
  created: function() {},
  name: "agent",
  methods: {
    validateNumber(number) {
      if (new PhoneNumber("+47" + number).isValid()) {
        return true;
      }
      return false;
    },
    validateForm: function(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Navn kreves.");
      }
      if (!this.email) {
        this.errors.push("E-post kreves.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Gyldig e-post kreves.");
      }
      if (!this.number) {
        this.errors.push("Nummer kreves.");
      } else if (isNaN(this.number)) {
        this.errors.push("Nummer kan kun inneholde heltall.");
      } else if (!this.validateNumber(this.number)) {
        this.errors.push("Nummer må være et godkjent Norsk telefonnummer");
      }

      if (!this.message) {
        this.errors.push("Melding kreves.");
      }

      if (this.errors.length === 0) {
        this.sendMail();
      }

      e.preventDefault();
    },
    validEmail: function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendMail() {

      this.$recaptcha("login")
        .then(token => {
          this.$store
            .dispatch(USER_SEND_VEILEDER_CONTACT_EMAIL, {
              message: this.message,
              email: this.email,
              mobile: this.number,
              name: this.name,
              has_skills: this.has_skills,
              recaptcha_token: token
            })
            .then(() => {
              this.$store.dispatch(USER_DISPLAY_RIBBON, {
                message: "Din søknad er nå sendt!"
              });
              this.name = "";
              this.email = "";
              this.number = "";
              this.message = "";
              this.has_skills = false;
              this.accepted_gdpr = false;
            })
            .catch(error => {
              this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
                message: "Noe gikk galt under utsending av epost"
              });
            });
        })
        .catch(error => {
          this.$store.dispatch(USER_DISPLAY_RIBBON_ERROR, {
            message:
              "Våre systemer kan ikke forsikre oss om at du er en person og ikke en robot. Vennligst ta kontakt med kundesupport dersom du mener dette er feil"
          });
        });
    }
  },
  computed: {}
};
</script>
